:root {
  --themeclr: #58305f;
  --second: #562f5d;
  --txtgray: #7a7b7d;
  --bordclr: #b0b0b0;
  --textgray: #484848;
  --adminblue: #562f5d;
  --white: #fff;
  --black: #000;
}

.cmn-btn {
  background: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  box-shadow: none !important;
  font-weight: 500;
  color: var(--white) !important;
  outline: none;
}

.cmn-btn:hover {
  background: transparent !important;
  border: 1px solid var(--themeclr) !important;
  color: var(--themeclr) !important;
}

.shadowbox {
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
}
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: "Roboto", sans-serif;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.cursor-pointer {
  cursor: pointer;
}
li {
  list-style-type: none;
}
.liststyledots li {
  list-style-type: disc;
}
.textgray {
  color: var(--textgray);
}
.fw-500 {
  font-weight: 500;
}
select.form-control {
  appearance: auto;
}
.themebg {
  background-image: linear-gradient(45deg, #082e53, #f6be09);
}
.mh-100vh {
  min-height: 100vh;
}
.mh-100vh-header {
  min-height: calc(100vh - 138px);
}
.mh-100vh-filterheader-footer {
  min-height: calc(100vh - 252px);
}
.themeclr {
  color: var(--themeclr);
}
.themehr {
  background-color: #858585;
}
.nounder {
  text-decoration: none;
}
.nolink {
  cursor: auto !important;
}
.upper {
  text-transform: uppercase;
}
/* common */
.themebord {
  border-color: #dadada;
}
.graytxt {
  color: #717171;
}
.flex-1 > * {
  flex: 1;
}
.jc-end {
  justify-content: end;
}
.jc-center {
  justify-content: center;
}
.jc-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.btn.disable {
  cursor: not-allowed !important;
}
a.navbar-brand img,
.logo img {
  max-width: 145px !important;
}

.br-10 {
  border-radius: 10px;
}
.form-switch .form-check-input {
  height: 20px;
}
p {
  margin-bottom: 0;
}
.bord {
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mt-78 {
  margin-top: 78px;
}
.f-50 {
  font-size: 50px;
}
.form-check {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.form-check-input:checked {
  background-color: var(--themeclr);
  border-color: var(--themeclr);
}
.countbtns button:hover {
  border-color: gray;
}
button.nofocus:focus {
  box-shadow: none !important;
}
.mb-90 {
  margin-bottom: 90px;
}
.height-100 {
  height: 100%;
}
.min-height-100vh {
  min-height: 100vh;
}
.w-full {
  width: 100%;
}
input::placeholder {
  font-size: 14px;
}
.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.f-14 {
  font-size: 14px;
}
.f-12 {
  font-size: 12px;
}

.themeclr {
  color: var(--themeclr);
}
.txtgray {
  color: var(--txtgray);
}
.bordclr {
  color: var(--bordclr);
}
.btn-theme {
  background: var(--themeclr) !important;
  color: var(--white) !important;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 5px;
  border-color: var(--themeclr) !important;
}
.btn-theme:hover {
  background-color: #212529 !important;
  border-color: #212529 !important;
  color: #fff !important;
}
.btnbord {
  border-radius: 8px;
}
select:focus,
input:focus,
textarea:focus {
  box-shadow: none !important;
}
.csvbtn button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  pointer-events: none;
  font-size: 12px;
}
.csvbtn {
  width: 100px;
  overflow: hidden;
  position: relative;
  border: 1px solid #dbdada;
  border-radius: 5px;
}

.note-group-select-from-files {
  display: none;
}

/* admin css */

#dashboard {
  display: flex;
  gap: 20px;
}
#sidebar {
  width: 200px;
  box-shadow: 14px 0px 7px -1px silver;
  border-radius: 0 10px 10px 0;
  position: absolute;
  background-color: #fff;
  transition: 0.5s;
  height: 100vh;
  position: fixed;
}
#sidebar ul li > span {
  padding-left: 38px;
  display: flex;
}
.showsidebar .adminsmalllogo {
  display: none;
}
.showsidebar .adminlogo {
  display: unset;
}
.adminlogo {
  display: none;
}
.adminlogo {
  height: 40px;
}
.adminsmalllogo {
  height: 30px;
}
.hidesidebar .sidebar ul span {
  display: none;
}
.hidesidebar #sidebar {
  width: 43px;
  transition: 0.5s;
}
.ham {
  height: 14px;
}
.sidebar {
  position: relative;
}
.sidebar ul {
  padding-left: 0;
  margin-top: 25px;
  height: calc(100vh - 160px);
  overflow-y: auto;
}
.sidebar ul li a:after {
  content: "";
  height: 100%;
  width: 0%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.2s;
}

.sidebar ul li:hover a:after,
.sidebar ul li a.active {
  width: 100%;
  background: var(--themeclr);
}
.sidebar ul li:hover span,
.sidebar ul li a.active span {
  color: #fff;
}

.sidebar ul li:hover img,
.sidebar ul li a.active img {
  filter: brightness(0) invert(1);
}
.sidebar ul li a {
  padding: 10px 0;
  padding-left: 12px;
  z-index: 9;
  position: relative;
  display: flex !important;
  align-items: center;
  text-decoration: none !important;
  width: 100%;
}
.sidebar ul li img {
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
  filter: brightness(0.5);
}
.sidebar ul li span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.hidesidebar .togglebtn {
  transform: rotate(178deg);
}
.showsidebar .togglebtn {
  transform: rotate(0);
}
button.togglebtn {
  transition: 0.5s;
  position: absolute;
  top: 65px;
  right: -9px;
  background: white;
  border: none;
  box-shadow: 0 0 5px silver;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.tabtable .nav-tabs {
  border-bottom: none;
}
.rdt_Table * {
  font-size: 16px;
}
.row.photos img {
  width: 100%;
}
.admin-forms .nav.nav-tabs {
  width: 100%;
  height: 43px;
  overflow-x: auto;
  flex-flow: nowrap;
}
.password input {
  border-right: 0;
  border-radius: 0.25rem 0 0 0.25rem;
}
.password .form-floating {
  width: calc(100% - 50px);
}

.note-popover .popover-content .note-dropdown-menu,
.note-editor .note-toolbar .note-dropdown-menu {
  min-width: 177px;
}
.note-modal .modal-header {
  flex-direction: column;
}
.note-btn-group .dropdown-toggle::after {
  display: none;
}
.note-editor.fullscreen {
  background: #fff;
}
.note-modal input[type="checkbox"] {
  margin-right: 5px;
}
.password button {
  width: 50px;
  border-left: 0;
  border-color: #ced4da;
}
.admin-forms .nav.nav-tabs li {
  white-space: nowrap;
}
.adminheader {
  box-shadow: 0px 1px 5px #d0d0d0;
}
.adminheader,
.cont {
  /* width: calc(100vw - 18px); */
  padding-left: 230px;
  transition: 0.5s;
  padding-right: 28px;
}
.headandcont {
  /* width: 100vw; */
  width: 98vw;
  margin-left: auto;
  margin-right: auto;
}
.hidesidebar .adminheader,
.hidesidebar .cont {
  /* width: 100vw; */
  padding-left: 70px;
  transition: 0.5s;
}
.adminheader {
  height: 72px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 5px;
}
#dashboard .rdt_TableHeadRow {
  color: #072d52;
  font-weight: 600;
}
.adminblue {
  color: var(--adminblue);
}
.circleprog {
  height: 100px;
  width: 100px;
  /* background: var(--themeclr); */
  background-image: conic-gradient(var(--themeclr) 344deg, #dedcdc 0deg);
  border-radius: 100%;
  position: relative;
}
.circleprog .overlay {
  position: absolute;
  height: 76px;
  width: 76px;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
span.title.timer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.dash-state {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}
.gradbox {
  padding: 21px;
  background-image: linear-gradient(45deg, #fff 27%, #ede9e9);
}
.date-pic .react-datepicker {
  width: 100%;
}
.date-pic .react-datepicker__header {
  width: 100%;
  background: transparent !important;
  border: 0 !important;
}
.date-pic .react-datepicker__month {
  width: 98%;
}
.date-pic .react-datepicker__month-container {
  float: unset !important;
}
.date-pic .react-datepicker__week,
.date-pic .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}
.date-pic .react-datepicker__week > div {
  /* flex: 1 1; */
  width: 40px !important;
  /* background: red; */
  /* border-radius: 50%; */
  aspect-ratio: 1/1;
  line-height: 3;
}
.date-pic .react-datepicker {
  border: 0 !important;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.admin-forms .form-floating>.form-control:focus~label,
/* .admin-forms .form-floating>.form-control:not(:placeholder-shown)~label,  */
.admin-forms .form-floating>.form-select~label {
  opacity: 1 !important;
  transform: scale(0.85) translateY(-0.7rem) translateX(1.15rem) !important;
  background: #fff;
  color: gray;
  text-align: left;
  display: inline-block;
  padding: 0;
  height: 20px;
}
.outlinebtn {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
}
.admin-forms label {
  color: gray;
}
.mobileadminlogo {
  height: 32px;
  display: none;
}
.mobiletog {
  display: none;
}
.admin-prof img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.admin-prof .show > .btn-success.dropdown-toggle,
.admin-prof .btn-success:hover {
  background-color: transparent !important;
}
.admin-prof button {
  background-color: transparent !important;
  border: 0;
}
.admin-prof button:after {
  display: none;
}
@media (max-width: 767px) {
  .adminheader,
  .cont {
    padding-left: 30px;
  }
  .mobileadminlogo {
    display: block;
  }
  .mobiletog {
    display: block;
  }
  #sidebar {
    width: 100%;
    z-index: 100;
  }
  button.togglebtn {
    right: 6px;
    top: 63px;
  }
  .hidesidebar #sidebar {
    width: 100%;
  }
  .hidesidebar .sidebar ul span {
    display: inline-block;
  }
  .showsidebar #sidebar {
    left: -130px;
  }
  .showsidebar .adminsmalllogo {
    display: none;
  }
  .hidesidebar .adminlogo {
    display: inline-block;
  }
  .adminsmalllogo {
    display: none;
  }
  .showsidebar #sidebar {
    width: 0;
  }

  .hidesidebar .adminheader,
  .hidesidebar .cont {
    padding-left: 20px;
  }
}
.h-55 select,
.h-55 input,
.spaninput input,
.spaninput select {
  height: 55px;
}

.upimages img {
  max-width: 80px;
  object-fit: cover;
  margin-right: 10px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upphoto button img {
  max-height: 143px;
  aspect-ratio: 1.5/1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.upphoto button {
  position: relative;
  border: 1px dashed gray;
  min-height: 145px;
  flex: 1;
  aspect-ratio: 1.5/1;
}
.upphoto button span {
  font-size: 50px;
  color: gray;
}
.upphoto button p {
  color: gray;
  font-size: 14px;
}
.upphoto button input {
  position: absolute;
  height: 100%;
  width: 100%;
  background: red;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9999;
}
.textbox {
  max-width: 100%;
  margin: auto;
  background: #fff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 2px;
}
.chatmsg img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  margin-right: 15px;
}
.chats {
  height: calc(100vh - 290px);
  position: relative;
  overflow-y: scroll;
}

.replymsg img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  margin-left: 15px;
}
.chats {
  margin-bottom: 0px;
}
.replymsg,
.chatmsg {
  max-width: 500px;
  padding-right: 20px;
  margin-bottom: 15px;
}
.replymsg p,
.chatmsg p {
  font-size: 15px;
}
.replymsg {
  text-align: right;

  margin-left: auto;
}

.as-react-table .row {
  margin-left: 0;
  margin-right: 0;
}
.tableview .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff;
}
.tableview .table-bordered > :not(caption) > * > * {
  border-width: 0;
}
.tableview .table > :not(:first-child) {
  border-width: 0;
}
.tableview th {
  font-size: 15px;
}
.tableview .asrt-table-head {
  margin-bottom: 40px;
}
.tableview td {
  font-size: 14px;
  color: gray !important;
}
.tableview .col-md-6.float-right.text-right {
  text-align: right;
}
.tableview .page-item a {
  color: var(--themeclr) !important;
}
.tableview .disabled.page-item a {
  color: #d2d2d2 !important;
}
.tableview span.input-group-text {
  font-size: 14px;
}
.tableview .table-foot {
  font-size: 14px;
}
.bluebg {
  background: #074687;
}
/* .adminlogin{
  background-image: url("../images/loginbg.jpg");
} */

/* / table / */

.table-foot {
  font-size: 14px;
}
thead tr th {
  white-space: nowrap;
}
.as-react-table .table-body .col-md-12 {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
}
.col-md-6.float-right.text-right input {
  margin-bottom: 6px;
}
.table-foot .col-md-6 {
  min-width: 216px;
  margin-bottom: 10px;
}

.table-head .col-md-6:first-child {
  width: 155px;
}
.table-head .col-md-6:last-child {
  flex: 1;
}
.asrt-table-head .col-md-6:last-child {
  text-align: right;
}
.asrt-table-head .col-md-6 {
  margin-top: 5px;
}
.table_tools button {
  margin-right: 5px;
  background: transparent;
  color: gray;
  border-color: #ced4da;
}
table {
  font-size: 14px;
}
tbody {
  border-top: 0 !important;
}
.btn-warning {
  background: #e88e26;
  border-color: #e88e26;
  color: #fff;
}
.btn-success {
  background: #48af48;
  border-color: #48af48;
  color: #fff;
}

.btn-trans {
  background: transparent;
  border: 0;
  text-align: left;
}

/* tr td .btn {
  margin-right: 1px;
  background: var(--themeclr) !important;
  border-color: var(--themeclr) !important;
  box-shadow: none !important;
}
tr td .btn:hover {
  background: var(--second);
  border-color: var(--second);
} */

.themescroll::-webkit-scrollbar-track {
  background: gray;
  border-radius: 5px;
}
.nav-item .nav-link.active {
  color: var(--adminblue);
  box-shadow: -2px -2px 5px #e7e7e7;
}
.nav-item .nav-link {
  color: #000;
}
.themescroll::-webkit-scrollbar-thumb {
  background: var(--themeclr);
  border-radius: 5px;
}
.themescroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.themescroll.hidescroll::-webkit-scrollbar {
  width: 0;
}
.themescroll {
  scrollbar-color: gray var(--themeclr) !important;
  scrollbar-width: thin !important;
}
tr td.action {
  white-space: nowrap;
}
.confirm input {
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.close_modal {
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25) !important;
}

a,
.btn-link {
  color: #0d6efd !important;
}
a:hover,
.btn-link:hover {
  color: var(--themeclr) !important;
}

.table th,
.table th.sortable {
  color: #072d52 !important;
}

.btn-primary:hover {
  background-color: var(--themeclr);
  border-color: var(--themeclr);

  color: #fff;
}

.btn-outline-dark {
  background-color: #212529 !important;
  border-color: #212529 !important;
  border-radius: 5px !important;
  color: #fff !important;
  min-width: 100px;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
}

.btn-outline-dark:hover {
  background-color: var(--themeclr) !important;
  border-color: var(--themeclr) !important;
  color: #fff !important;
}
.input-group.asrt-page-length {
  align-items: center;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #222 !important;
  border-color: #222 !important;
}

.hidesidebar .sidebar ul li img {
  margin-left: -5px;
  max-width: 80%;
}

.page-link {
  color: #000 !important;
}
.page-link:hover {
  color: var(--themeclr) !important;
}
.dropdown-toggle.btn-success:focus {
  background-color: transparent !important;
  border-color: transparent !important;

  box-shadow: 0 0 0 0rem rgba(72, 180, 97, 0.5) !important;
}
.upimag_sidue.upimages img {
  max-width: 150px !important;
}
.rdt_TableCell,
.rdt_TableCol,
.rdt_Table * {
  font-size: 14px !important;
}
.rdt_TableCol {
  color: #072d52 !important;
}
.amenity_table_hide_head .rdt_TableHeadRow {
  display: none;
}
.mobiletog.btn-link {
  color: var(--themeclr) !important;
}

.instant_bookinsw label {
  min-width: 200px;
}

@media only screen and (max-width: 575px) {
  .btn_view_oneline.btn_view_oneline_mor_bt .btn {
    min-width: 100% !important;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .btn_view_oneline.btn_view_oneline_mor_bt .btn.me-3 {
    margin-right: 5px !important;
  }
  .btn_view_oneline.btn_view_oneline_mor_bt {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #e9ecef !important;
}
.dropdown-item {
  color: var(--themeclr) !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--themeclr);
  color: white;
}

.hotels_guestDetails {
  padding: 30px 10px;
  display: flex;
  align-items: center;
  gap: 30px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.hotels_guestDetails .list {
  text-wrap: nowrap;
  border-bottom: 2px solid transparent;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 10px;
  cursor: pointer;
}
.hotels_guestDetails .list:hover {
  border-bottom: 2px solid var(--themeclr);
  color: var(--themeclr);
}
.hotels_guestDetails .list.active {
  border-bottom: 2px solid var(--themeclr);
  color: var(--themeclr);
}

.guest_Table_data .details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.guest_Table_data .details .userImg {
  height: 30px;
  border-radius: 6px;
  width: 30px;
  object-fit: cover;
}
.guest_Table_data .details .name {
  font-size: 14px;
  /* font-weight: 500; */
}
.guest_Table_data .details .empId {
  font-size: 11px;
  font-weight: 500;
  color: var(--txtgray);
}

/* .hotels_datas thead tr th {
  min-width: 200px;
} */

.hotels_datas .guest_Table_data {
  min-width: 200px;
}
.hotels_datas .date_order {
  min-width: 180px;
}
.hotels_datas .date_order .val {
  font-size: 14px;
}
.hotels_datas .check_In_Out {
  min-width: 150px;
}
.hotels_datas .check_In_Out .time {
  color: var(--txtgray);
  font-size: 12px;
}

/* .hotels_datas .check_In_Out {
  min-width: 150px;
} */
.hotels_datas .Special_Request .view_notes {
  background-color: rgba(19, 88, 70, 0.1) !important;
  font-size: 12px;
  color: #5a4f4f;
  border-radius: 5px;
  padding: 6px 15px;
  border: none;
  outline: none;
}

.hotels_datas .room_types {
  min-width: 150px;
}

.hotels_datas .status .cmnBtn {
  font-size: 12px;
  border-radius: 5px;
  padding: 6px 15px;
  border: none;
  outline: none;
}
.hotels_datas .status {
  min-width: 120px;
}

.hotels_datas .status .cmnBtn.refund {
  background-color: rgba(226, 52, 40, 0.1) !important;
  color: #e23428;
}

.hotels_datas .status .cmnBtn.pending {
  background-color: #fb9f44 !important;
  color: #fff;
}

.hotels_datas .status .cmnBtn.booked {
  background-color: #68e365 !important;
  color: #fff;
}

.hotels_datas .status .cmnBtn.cancelled {
  background-color: #e23428 !important;
  color: #fff;
}

.change_pwd .form-floating {
  display: flex;
}

.change_pwd .form-floating .pwd_hide_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.change_pwd .form-floating .form-control {
  font-size: 13px;
}

.multi_select_all button {
  width: 100% !important;
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.dash_board .cusCard {
  padding: 21px;
  background: white;
  box-shadow: 0px 0px 10px 2px #dcdcdc;
  border-radius: 10px;
}

.dash_board .cusCard .heading_txt {
  color: #6c757d !important;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.dash_board .cusCard .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash_board .cusCard .image img {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.dash_board .hotel_card {
  padding: 1.5rem;
  /* background-image: linear-gradient(45deg, #fbf3f3 10%, #ede9e9 50%); */
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  /* box-shadow: 0 0.3125rem 0.3125rem #523f690d; */
  background: white;
  box-shadow: 0px 0px 10px 2px #dcdcdc;
}

.dash_board .hotel_card:hover {
  box-shadow: 0 1.188rem 1.188rem #dcdcdc !important;
}
.dash_board .hotel_card:hover .icon {
  background-color: #58305f;
}

.dash_board .hotel_card:hover .icon svg {
  color: white;
}

/* .dash_board .hotel_card .icon:hover {
  background-color: #58305f;
}
.dash_board .hotel_card .icon:hover svg {
  color: white;
} */

.dash_board .hotel_card .icon {
  height: 50px;
  width: 55px;
  border-radius: 0.5rem;
  background: rgba(226, 52, 40, 0.1);
  text-align: center;
  line-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all;
}
.dash_board .hotel_card .icon svg {
  font-size: 25px;
  color: #58305f;
  transition: 0.5s all;
}

.dash_board .hotel_card .booking-status {
  gap: 20px;
}
.dash_board .hotel_card .details .count {
  font-weight: 700;
}
.dash_board .hotel_card .details .name {
  font-weight: 700;
  color: #999494;
  font-size: 12px;
}

.reviews_listTable table tbody tr td {
  text-align: center;
  vertical-align: middle;
}
.reviews_listTable table tbody tr .reviews .reviews_data {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reviews_listTable table tbody tr .reviews .reviews_data p {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.review_previewModal .user_details .label_txt {
  color: #6c757d !important;
  font-weight: 500;
}
.review_previewModal .user_details .val {
  font-weight: 500;
}

.review_previewModal .user_details .user_logo {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.recent_bookings {
  padding: 17px 25px 25px;
  background: white;
  box-shadow: 0px 0px 10px 2px #dcdcdc;
  border-radius: 10px;
}
.recent_bookings .heading_txt {
  font-weight: 500;
}

.recent_bookings .btn_bdr {
  background-color: #dcdcdc;
}

.recent_bookings .room_user_info .room {
  display: flex;
  gap: 20px;
  align-items: center;
}

.recent_bookings .room_user_info .room .roomImg {
  border-radius: 10px;
  width: 150px;
  height: 90px;
  object-fit: cover;
}

.recent_bookings .room_user_info .userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.recent_bookings .room_user_info .userInfo .userImg {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.recent_bookings .room_user_info .userInfo .name {
  color: #6e6e6e;
  font-weight: 600;
  font-size: 15px;
}
.recent_bookings .room_user_info .userInfo .time {
  color: #6e6e6e;
  /* font-weight: 600; */
  font-size: 12px;
}

.recent_bookings .room_user_info .booked_date {
  border-radius: 7px;
  padding: 7px 15px;
  color: var(--white);
  font-weight: 500;
  background-color: var(--themeclr);
  width: fit-content;
}

.recent_bookings .view_moreBtn {
  cursor: pointer;
}

.recent_bookings .view_moreBtn:hover {
  color: var(--themeclr);
}

.recent_bookings .view_moreBtn .loadIcon {
  animation: loadRotate 10s linear infinite;
}

@keyframes loadRotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 3600deg;
  }
}

/* datePicker start */

.cmn_datePicker .react-datepicker {
  border: none;
  background-color: transparent;
  width: 100%;
}

.cmn_datePicker .react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.cmn_datePicker .react-datepicker__day {
  /* width: 55px; */
  height: 55px;
  line-height: 55px;
  width: 100%;
}
.cmn_datePicker .react-datepicker__day-name {
  /* width: 55px; */
  width: 100%;
}
.cmn_datePicker .react-datepicker__day--selected {
  background-color: var(--themeclr);
  color: var(--white);
}
.cmn_datePicker .react-datepicker__day--keyboard-selected {
  background-color: var(--themeclr);
  color: var(--white);
}
.cmn_datePicker .react-datepicker__day-names {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
}
.cmn_datePicker .react-datepicker__current-month {
  font-size: 20px;
  font-weight: 600;
}
.cmn_datePicker .react-datepicker__navigation {
  top: 10px;
}

.cmn_datePicker .react-datepicker__month-container {
  width: 100%;
}
.cmn_datePicker .react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
}

/* datePicker end */

.dash_board .room_details_info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.dash_board .room_details_info .cmn_rm {
  padding: 1.5rem;
  background: white;
  box-shadow: 0px 0px 10px 2px #dcdcdc;
  border-radius: 10px;
}
.dash_board .room_details_info .rooms_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
}
.dash_board .room_details_info .rooms_count .value {
  font-size: 20px;
  font-weight: 700;
}
.dash_board .room_details_info .progress {
  margin-top: 12px;
  border-radius: 20px;
}

.dash_board .room_details_info .progress-bar {
  background-color: var(--themeclr);
}

.dash_board .total_rooms_cnt {
  padding: 1.5rem;
  border-radius: 0.75rem;
  background: white;
  box-shadow: 0px 0px 10px 2px #dcdcdc;
  border-radius: 10px;
}

.dash_board .total_rooms_cnt .item .label_txt {
  color: #6e6e6e;
  font-size: 15px;
}
.dash_board .total_rooms_cnt .item .cnt {
  font-weight: 700;
  font-size: 23px;
}

.dash_board .total_rooms_cnt .room_total {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.total_rooms_cnt .annual_report {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.annual_report .arrow_icon {
  /* font-size: 18px; */
  min-width: 18px;
  min-height: 18px;
  opacity: 0.8;
  cursor: pointer;
  transition: 1s all;
}
.annual_report .arrow_icon:hover {
  opacity: 1;
  transform: scale(1.1);
}

.customselect__single-value {
  color: black !important;
}
.err_msg_txt {
  color: red;
  font-size: 12px;
}

.forgotPwd_inp {
  display: flex;
  align-items: center;
}

.forgotPwd_inp button {
  height: 58px;
  border: 1px solid #ced4da !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #6c757d !important;
}

.forgotPwd_inp .form-control {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.blog .custom_uploadBtn input[type="file"] {
  max-width: 100%;
  padding: 5px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #cde2e7 !important;
  border-radius: 0.25rem !important;
  color: #6c757d !important;
  width: 100%;
  height: 58px;
}

.blog .custom_uploadBtn input[type="file"]::file-selector-button {
  margin-right: 15px;
  margin-top: 8px;
  margin-left: 5px;
  border: none;
  background: var(--adminblue);
  padding: 7px 20px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  /* position: relative;
  top: 10px; */
}

.blog .preview_img {
  width: 100px;
  height: 100px;
}
.blog .preview_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.table_img.status {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.blog_statusBtn {
  padding: 5px 12px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px;
  border: none !important;
}

.blog_statusBtn.deactive {
  background: #e23428 !important;
  color: var(--white) !important;
}
.blog_statusBtn.active {
  background: hsl(119, 92%, 60%) !important;
  color: var(--white) !important;
}

.hotelTable.Img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.hotel_customModal .modal-header {
  border-bottom: none;
}

.hotel_preview_Img {
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
}

.hotel_preview_Img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.hotel_customModal .modal-title {
  font-size: 20px;
  text-align: center;
  width: 100%;
}
.hotel_customModal .modal-header {
  padding-bottom: 0px;
}

.user_profile_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* custom tabs */

.navCustom_tabs {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 8px;
  overflow-x: auto;
}

.navCustom_tabs .nav-item .nav-link {
  background: transparent !important;
  color: var(--black) !important;
  border: 1px solid var(--themeclr) !important;
  padding: 7px 20px;
  font-size: 15px;
}

.navCustom_tabs .nav-item .nav-link:hover {
  background: var(--themeclr) !important;
  color: var(--white) !important;
  border-color: var(--themeclr) !important;
}

.navCustom_tabs .nav-item .nav-link.active {
  background: var(--themeclr) !important;
  color: var(--white) !important;
  border-color: var(--themeclr) !important;
}

@media (max-width: 480px) {
  .btn-theme {
    min-width: 70px;
  }
  .btn-outline-dark {
    min-width: 70px;
  }
  .cmn_datePicker .react-datepicker__day {
    height: 45px;
    line-height: 45px;
  }
  .dash_board .room_details_info {
    grid-template-columns: repeat(1, 1fr);
  }
  .dash_board .total_rooms_cnt .room_total {
    grid-template-columns: repeat(1, 1fr);
  }
  .recent_bookings .room_user_info .room {
    flex-direction: column;
    align-items: start;
  }
  .confirm input {
    width: 40px !important;
    height: 40px !important;
  }
  .hotel_preview_Img {
    min-width: 280px;
    max-width: 100%;
    width: 100%;
    height: 280px;
    min-height: 280px;
    max-height: 280px;
  }
}
@media (max-width: 767px) {
  .showsidebar .adminlogo {
    display: none;
  }
}
@media (min-width: 1400px) {
  .cmn_datePicker .react-datepicker__day {
    height: 65px;
    line-height: 65px;
  }
}
